<template>
    <div class="invest_project_wrapper">
        <div class="wrapper_header grid page_grid gap-5 2xl:gap-8">
            <div class="w-full flex flex-col place-content-between">
                <div class="flex items-baseline">
                    <a-button type="ui" ghost flaticon shape="circle" size="large" icon="fi-rr-arrow-small-left" class="back_button mr-2" @click="backProject()" />
                    <h2 v-if="project" class="" :title="project.project_name">
                        {{ project.project_name }}
                    </h2>
                    <a-skeleton v-else active :paragraph="{ rows: 0 }" />
                </div>
                <div class="tab_buttons">
                    <a-button type="primary" size="large" :block="isMobile" :ghost="$route.name === 'full_invest_project_info' ? false : true" class="" @click="changeTab('full_invest_project_info')">
                        Информация о проекте
                    </a-button>
                    <a-button type="primary" size="large" :block="isMobile" :ghost="$route.name === 'full_invest_project_documents' ? false : true" class="" @click="changeTab('full_invest_project_documents')">
                        Документы
                    </a-button>
                    <a-button type="primary" size="large" :block="isMobile" :ghost="$route.name === 'full_invest_project_timeline' ? false : true" @click="changeTab('full_invest_project_timeline')">
                        История изменений
                    </a-button>
                </div>
            </div>
            <div>
                <div class="header_block">
                    <template v-if="project">
                        <div class="header_block_label">Проект добавлен</div>
                        <div class="block_list">
                            <div v-if="project.organization" class="block_list__item">
                                <div class="name">Организация: </div>
                                <div class="value">{{ project.organization.name }}</div>
                            </div>
                            <div v-if="project.author" class="block_list__item">
                                <div class="name">Автор проекта: </div>
                                <div class="value">
                                    <Profiler :user="project.author" :avatarSize="18" />
                                </div>
                            </div>
                            <div v-if="project.project" class="block_list__item">
                                <a-button size="large" @click="openProjectTasks">
                                    Задачи проекта
                                </a-button>
                            </div>
                            <div v-else-if="actionInfo.add_project && !project.project" class="block_list__item">
                                <a-button size="large" @click="createProject">
                                    Создать проект
                                </a-button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <router-view 
            :loading="loading" 
            :actionInfo="actionInfo" 
            :project="project" />
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { useTitle } from '@vueuse/core'
export default {
    data() {
        return {
            loading: false,
            project: null,
            actionInfo: null
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    methods: {
        openProjectTasks() {
            this.$router.replace({
                query: { viewGroup: this.project.project }
            })
        },
        createProject() {
            this.$confirm({
                title: 'Создать проект с задачами для инвестиционного проекта?',
                content: '',
                okText: 'Создать',
                cancelText: 'Отмена',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post(`/invest_projects_info/${this.$route.params.id}/add_project/`)
                            .then((response) => {
                                this.$set(this.project, 'project', response.data)
                                this.$message.success('Проект создан')
                                resolve()
                            })
                            .catch(e => {
                                this.$message.error('Ошибка создания проекта')
                                console.log(e)
                                reject(e)
                            })
                    })
                },
                onCancel() {},
            })
        },
        backProject() {
            this.$router.push({ name: 'invest-project' })
        },
        changeTab(name) {
            if(this.$route.name !== name)
                this.$router.push({ name })
        },
        async getInvestActions() {
            try{
                const { data } = await this.$http.get(`/invest_projects_info/${this.$route.params.id}/action_info/`)
                if(data?.edit && data.edit.length) {
                    const object = {}
                    data.edit.forEach(item => {
                        object[item] = true
                    })
                    this.actionInfo = object
                }
            } catch(e) {
                console.log(e)
            }
        },
        async getProject() {
            try {
                this.loading = true
                const { data } = await this.$http.get(`/invest_projects_info/${this.$route.params.id}/`)
                if(data) {
                    this.project = data
                    useTitle(data.project_name)
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    created() {
        this.getProject()
        this.getInvestActions()
    },
    mounted() {
        eventBus.$on('update_invest_full_project', (data) => {
            this.project = data
        })
    },
    beforeDestroy() {
        eventBus.$off('update_invest_full_project')
    }
}
</script>

<style lang="scss" scoped>
.header_block{
    background: #fff;
    padding: 15px;
    border-radius: var(--borderRadius);
    @media (min-width: 768px) {
        background: #eff2f5;
        padding: 10px 20px;
    }
    @media (min-width: 1700px){
        padding: 15px 30px;
    }
    .header_block_label{
        font-size: 16px;
        color: #000000;
        margin-bottom: 10px;
    }
    .block_list{
        font-size: 14px;
        &__item{
            @media (min-width: 768px) {
                display: flex;
            }
            &:not(:last-child){
                margin-bottom: 10px;
            }
            .name{
                color: #000000;
                opacity: 0.6;
                word-break: break-word;
                @media (min-width: 768px) {
                    min-width: 150px;
                    max-width: 150px;
                    padding-right: 20px;
                }
                @media (min-width: 1200px) {
                    min-width: 150px;
                    max-width: 150px;
                }
            }
            .value{
                color: #000000;
                word-break: break-word;
            }
        }
    }
}
.invest_project_wrapper{
    padding: 20px;
    .back_button{
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .wrapper_header{
        margin-bottom: 15px;
        grid-template-columns: 1fr;
        @media (min-width: 768px) {
            margin-bottom: 30px;
        }
        @media (min-width: 1100px) {
            grid-template-columns: 420px 1fr;
        }
        @media (min-width: 1200px) {
            grid-template-columns: 520px 1fr;
        }
        @media (min-width: 1400px) {
            grid-template-columns: 620px 1fr;
        }
        @media (min-width: 1600px) {
            grid-template-columns: 720px 1fr;
        }
    }
    h2{
        color: #000;
        font-size: 22px;
        margin: 0px;
        @media (min-width: 768px) {
            font-size: 32px;
        }
    }
    .tab_buttons{
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        @media (max-width: 767px) {
            flex-direction: column;
        }
        @media (min-width: 768px) {
            display: flex;
            align-items: center;
            margin-top: 20px;
        }
        &::v-deep{
            .ant-btn{
                @media (max-width: 768px) {
                    &:not(:last-child){
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
</style>